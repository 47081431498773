/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-07-11 16:03:11
 * @FilePath: \js-yigrow-system\src\stores\customize-theme.js
 * @Description: 主题设置
 */

import { defineStore } from "pinia";

export const useCustomizeThemeStore = defineStore({
  id: "customizeTheme",
  state: () => ({
    miniSidebar: false,
    darkTheme: false,
    primaryColor: {
      colorId: 2,
      colorName: "grey",
      colorValue: "#344767",
    },
    localCode: "zhHans",
    mainSidebar: true,
    isMobile: false,
    // mainSidebar: isMobile ? false : true,
  }),
  
  persist: {
    paths: ["darkTheme", "primaryColor", "localCode", "mainSidebar","miniSidebar"],
    storage: sessionStorage,
  },



  actions: {
    setMiniSideBar(val) {
      // console.log(val);
      this.miniSidebar = val;
    },
    setPrimaryColor(payload) {  // Color
      this.primaryColor = payload;
    },
    setLocalCode(localCode) {
      this.localCode = localCode;
    },
    checkDeviceType() {
      const screenWidth = window.innerWidth;
      const isMobileByWidth = screenWidth <= 767;

      const userAgent = navigator.userAgent;
      const isMobileByAgent =
        /phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(
          userAgent
        );

      this.isMobile = isMobileByWidth || isMobileByAgent;
    },
  },

})
