<!--
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-03-22 11:07:00
 * @FilePath: \js-yigrow-system\src\layouts\auth-layout.vue
 * @Description: auth主题
-->
<template>
  <div class="d-flex text-center flex-grow-1">
    <v-sheet class="layout-side d-none d-md-flex flex-column justify-space-between">
      <div class="mt-3 mt-md-10 pa-2">
        <div class="text-primary text-h4 font-weight-bold">{{$t('app.appTitle')}}</div>
        <div class="title my-2">
         {{$t('app.welcomeText')}}
        </div>
      </div>
    </v-sheet>
    <v-card variant="outlined" class="flex-grow-1 d-flex align-center justify-center flex-column">
      <div class="layout-content ma-auto w-full">
        <slot></slot>
      </div>
      <p class="text-center text-body-2 py-5">
        <span>© 尚禾-{{ yearVal }}</span>
        <span class="ml-3">{{$t('app.systemTitle')}} v{{ version }}</span>
        <!-- <a class="text-blue-grey mx-5" href="#">隐私政策</a>
          <a class="text-blue-grey" href="#">服务条款</a> -->
      </p>
    </v-card>
  </div>
</template>
<script setup>
import { version } from '@/configs';

const yearVal = ref(new Date().getFullYear())

</script>

<style lang="scss" scoped>
.layout-side {
  width: 420px;
}

.layout-content {
  max-width: 480px;
}
</style>
