<!--
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-06-04 09:40:08
 * @FilePath: \js-yigrow-system\src\App.vue
 * @Description: 
-->
<template>
  <v-app :theme="customizeThemeStore.darkTheme ? 'dark' : 'light'">
    <component :is="currentLayout" v-if="isRouterLoaded">
      <router-view> </router-view>
    </component>
    <customization-menu />
    <back-top />
  </v-app>
</template>

<script setup>
import uiLayout from "@/layouts/ui-layout.vue"
import landingLayout from "@/layouts/landing-layout.vue"
import defaultLayout from "@/layouts/default-layout.vue"
import authLayout from "@/layouts/auth-layout.vue"
import { useCustomizeThemeStore } from "@/stores/customize-theme"
import { useUserStore } from "./stores/user"

useUserStore().initUser()

const customizeThemeStore = useCustomizeThemeStore()
const route = useRoute()

const isRouterLoaded = computed(() => {
  if (route.name !== null) return true
  return false
})

const layouts = {
  default: defaultLayout,
  ui: uiLayout,
  landing: landingLayout,
  auth: authLayout,
  error: defaultLayout,
}

const currentLayout = computed(() => {
  const layoutName = route.meta.layout
  if (!layoutName) {
    return defaultLayout
  }
  return layouts[layoutName]
})

onMounted(() => {
  customizeThemeStore.checkDeviceType()
})

// 监听窗口变化
window.addEventListener("resize", () => {
  customizeThemeStore.checkDeviceType()
})
</script>

<style lang="scss"></style>