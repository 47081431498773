import { Base } from './base'

class Model extends Base {
  constructor(opt) {
    super(opt)
  }
  /**
   * 验证码登录，若携带密码，则为修改密码
   * @param {object}  { phone, code, password }
   * @returns 
   */
  async loginByCode({ phone, code, password }) {
    return this.get('/user/login/sms', { data: { phone, code, password } })
  }

  async registerByCode(code, state) {
    return this.get('/user/register', { query: { code, state } })
  }

  async loginByUsername(username, password, captcha, tag) {
    return this.post('/user/login', { username, password, captcha, tag })
  }

  async login(username, password) {
    return this.get('/user/login', { data: { username, password } })
  }

  async getCaptcha() {
    return this.get('/user/captcha')
  }

  async getUserList(page, pageSize, key) {
    let query = key ? { page, pageSize, key } : { page, pageSize }
    return this.get('/user/page', { query })
  }

  async getCustomerList(page, pageSize, key) {
    let query = key ? { page, pageSize, key } : { page, pageSize }
    return this.get('/user/customer-page', { query })
  }

  async getPermissions(userId) {
    return this.get('/user/permissions', { query: { userId } })
  }

  // 发送手机验证码
  async getSmsCode(phone) {
    return this.get(`/mobile-code?phone=${phone}`)
  }

  // 切换当前所属商户
  subAccount(bisId) {
    return this.put('/bis', { bisId })
  }
  changeMerchant(data) {
    return this.put(`/bis`, data)
  }
}

export default new Model({ prefix: '/v1/token' })
