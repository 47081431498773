/**
 * 帐户信息
 */
import { defineStore } from 'pinia'
import token from '@/models/token.js'
import user from '@/models/user-api.js'
// import user from '@/models/user.js'
// import biz from '@/models/biz.js'
import wx from '@/models/wx.js'
import { useAuthStore } from './auth.js'
// import { useBisStore } from './bis.js'
import { showNotify } from '@/utils/show.js'

export const useUserStore = defineStore(
  'user',
  () => {
    const userInfo = ref() // 当前子帐户  info
    const phone = ref('') // 上次输入手机啊
    const bisList = ref([]) // 所有子帐户的基地

    async function getBisAll() {
      let res = await user.getBisAll()
      bisList.value = res
      return res
    }
    // 切换 子帐户
    async function subAccount(bisId) {
      try {
        let res = await token.subAccount(bisId)
        useAuthStore().logoutRmToken()
        userInfo.value = null
        await geUserInfo()
        // await getBisInfo()
      } catch (e) {
        showNotify(e.msg || e.message, 'warning')
      }
    }

    async function geUserInfo() {
      try {
        let res = await user.getUserInfo()
        userInfo.value = res
        phone.value = res.phone
        // bisId.value = res.id
        // name.value = res.name
      } catch (e) {
        showNotify(e.msg || e.message, 'warning')
      }
    }

    // 注册用户 {nickName, username, password, password, email}
    async function register({ phone, code, ...data }) {
      let res = await user.addAdmin({ phone, code, ...data })
      // let res = await user.registerBySmsCode({ phone, code, ...data })
      // if (res.accessToken) {
      showNotify('成功注册',)
      // userInfo.value = res
      useAuthStore().phone = phone
    }

    // 账号密码登录
    async function login(username, password) {
      // try {
      let res = await token.login(username, password)
      // console.log("登录反馈>>>",res);
      // if (res.accessToken) {
      // showNotify('登录成功',)
      // userInfo.value = res
      phone.value = res.phone
      useAuthStore().saveAuth(res)
      initUser()
      // } catch (e) {
      //   showNotify({
      //     message: e.msg || e.message || '登录失败',
      //     type: 'warning'
      //   })
      // }
    }

    /**
     * 验证码登录，若携带密码，则为修改密码
     * @param {object}  { phone, code, password }
     * @returns 
     */
    async function loginByCode(data) {
      // try {
      let res = await token.loginByCode(data)
      console.log(res)
      showNotify('成功登录', 'success')
      // userInfo.value = res
      phone.value = res.phone
      useAuthStore().saveAuth(res)
      initUser()
      // } catch (e) {
      //   showNotify({
      //     message: e.msg || e.message || '验证码登录失败',
      //     type: 'warning'
      //   })
      // }
    }

    function logout() {
      useAuthStore().logoutRmToken()
      userInfo.value = null
      bisList.value = []
    }

    // 微信授权登录
    async function loginByWxCode({ code, state }) {
      // try {
      let res = await wx.loginByCode({ code, state })
      useAuthStore().saveAuth(res)
      // if (res.accessToken) {
      showNotify('微信自动登录成功',)
      // userInfo.value = res
      phone.value = res.phone
      initUser()
      // } catch (e) {
      //   showNotify({
      //     message: e.msg || e.message,
      //     type: 'warning'
      //   })
      //   throw e
      // }
    }
    // 微信授权注册
    async function registerByWxCode({ code, state }) {
      // try {
      let res = await wx.registerByCode({ code, state })
      showNotify('微信一键注册成功',)
      // userInfo.value = res
      phone.value = res.phone
      useAuthStore().saveAuth(res)
      initUser()
      // } catch (e) {
      //   console.error('registerByWxCode:', e)
      //   showNotify({
      //     message: e.msg || e.message,
      //     type: 'warning'
      //   })
      // throw e
      // }
    }
    // 微信 一键注册(绑定)
    async function bindingByWxCode(data) {
      // const { code, state, phone, smsCode, password } = data
      // try {
      let res = await wx.bindingByCode(data)
      showNotify('微信一键注册成功',)
      // userInfo.value = res
      phone.value = res.phone
      useAuthStore().saveAuth(res)
      initUser()
      // } catch (e) {
      //   console.error('bindingByWxCode:', e)
      //   showNotify({
      //     message: e.msg || e.message,
      //     type: 'warning'
      //   })
      //   // throw e
      // }
    }

    // 微信 一键注册(绑定 username)
    async function bindingByWxCodeAndUsername(data) {
      // {code, username, password}
      let res = await wx.bindingByCodeAndUsername(data)
      showNotify('微信一键注册成功',)
      // userInfo.value = res
      phone.value = res.phone
      useAuthStore().saveAuth(res)
      initUser()
    }

    async function sendSms(phone) {
      try {
        let res = await token.getSmsCode(phone)
        showNotify(res.message)
      } catch (e) {
        console.log('send sms err:', e)
        showNotify(e.message, 'error')
      }
    }
    // 初始化个人信息
    async function initUser() {
      let { token } = useAuthStore()
      if (!token) return
      console.log(' -------- start init user bis...')
      await getBisAll()
      if (!bisList.value?.length) {
        showNotify('你没有所属基地，请加入某个基地或创建自己的基地', 'warning')
      }
      userInfo.value = await user.getUserInfo()
      // permission.value = await userApi.getPermissions()
      // await useBisStore().initBis()

    }
    return {
      userInfo,
      bisList,
      phone,
      subAccount,
      geUserInfo,
      getBisAll,
      register,
      login,
      loginByCode,
      logout,
      loginByWxCode,
      registerByWxCode,
      bindingByWxCode,
      sendSms, initUser,
      bindingByWxCodeAndUsername,
    }
  },
  {
    persist: true // default localStorage
    // persist: { key: 'store:app', } // default $id
  }
)
