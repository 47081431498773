/**
 * zhaixiang 20231116
 */

/**
 * 拓展Date方法: date.format('yyyy-MM-dd')
 * 'yyyy-MM-dd HH:mm' 'yyyy/MM/dd' 'yyyy.MM.dd' 'dd.MM.yy' 'yyyy.dd.MM'
 */
Date.prototype.format = function (format) {
  var o = {
    'M+': this.getMonth() + 1, //month
    'd+': this.getDate(), //day
    'h+': this.getHours(), //hour
    'm+': this.getMinutes(), //minute
    's+': this.getSeconds(), //second
    'q+': Math.floor((this.getMonth() + 3) / 3), //quarter
    S: this.getMilliseconds() //millisecond
  }
  if (/(y+)/.test(format))
    format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  Object.keys(o).forEach(k => {
    if (new RegExp(`(${k})`).test(format))
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
  })
  return format
}

export function numFmt(num, n = 2) {
  num = +num
  return num > 1048576 ? (num / 1048576).toFixed(n) + 'M' : num > 1024 ? (num / 1024).toFixed(n) + 'K' : num
}
/**
 * 
const {promise, resolve, reject} = new Deferred()

return promise
 */
export class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
}

/**
 * 生成 uri 字符串, encodeURIComponent() 处理
 * 如传 url, baseUrl 自动拼接 
 * @param {object} query uri 参数
 * @param {string} url 
 * @param {string} baseUrl 
 * @returns 
 */
export function qs(query, url = '', baseUrl) {
  if (baseUrl) {
    let urlObj = new URL(url, baseUrl)
    if (query && type(query) === "object") Object.keys(query).filter(k => query[k] !== undefined && query[k] !== null).forEach(i => urlObj.searchParams.append(i, query[i]))
    url = urlObj.toString()
  } else if (query && type(query) === "object") {
    let qs = Object.keys(query).filter(k => query[k] !== undefined && query[k] !== null).map(i => `${i}=${encodeURIComponent(query[i])}`).join('&')
    url += (url.includes('?') ? '&' : '?') + qs
  }
  return url
}

/**
 * 查询 对象的具体 名称 formdata, blob, urlsearchparams, null, string, undefined, object, array, function, regexp, map, set, symbol
 * @param {object} obj 
 * @returns {string}
 */
export function type(obj) {
  return Object.prototype.toString.call(obj)
    .match(/\[object (.*?)\]/)[1]
    .toLowerCase()
}

/**
 * 异步延时ms, 不阻塞主线程
 * @param {number} ms
 * @returns
 */
export function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function isWechat() {
  return /MicroMessenger/i.test(window.navigator.userAgent)
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export function selectProp(obj, map, type = {}) {
  return Object.keys(map).map(key => {
    let res = { title: map[key], value: obj[key] }
    type[key] && (res[type[key]] = true)
    type[key] === 'checked' && (res.value = !!obj[key])
    type[key] === 'switch' && (res.value = !!obj[key])
    res.key = key
    return res
  })
}

/**
 * 从对象中 指定属性 生成新对象 'prop1 prop2'|['prop1', 'prop2']
 *  可用空格、逗号字符串或数组 分隔; 
 *  '-'表示要去除的
 */
export function selectProps(obj = {}, properties) {
  if (properties.includes('-')) return omit(obj, properties)
  return slim(obj, properties)
}

/**
 * 瘦身对象 (只留部分)
 *  slim({ name: 'Benjy', age: 18 }, 'name') => { name: 'Benjy' }
 *  分隔符为 正则 \s和','
 * @param {object} obj
 * @param {array|string} properties 'prop1 prop2,prop3',['prop1']
 * @returns {object}
 */
export function slim(obj, properties) {
  if (!properties || properties.length === 0) return null
  if (!Array.isArray(properties)) properties = properties.replace(/\s/g, ',').split(',').filter(i => i)
  return properties.reduce((p, c) => (p[c] = obj[c], p), {})
}

/**
 * 瘦身对象 (排除异己)
 *  omit({ name: 'Benjy', age: 18 }, 'name') => { age: 18 }
 *  分隔符为 正则\s 和 ','
 * @param {object} obj
 * @param {array|string} properties 'prop1 prop2,prop3',['prop1']
 * @returns {object}
 */
export function omit(obj, properties) {
  if (!properties || properties.length === 0) return obj
  if (!Array.isArray(properties)) properties = properties.replace(/\s/g, ',').split(',').filter(i => i)
  return Object.entries(obj).reduce((p, [k, v]) => !properties.includes(k) ? (p[k] = v, p) : p, {})
}

// 去掉 空属性 null, undefined, NaN
export function selectPropExEmpty(obj) {
  return Object.entries(obj)
    .reduce((acc, [k, v]) =>
      v || v === 0 || v === ''
        ? (acc[k] = v, acc)
        : acc,
      {})
}

/**
 * 返回 {'user':[{titel:'auth1', checked: true}]}
 * @param {object} obj {'user':['auth1']}
 * @returns 
 */
export function checkedList(obj) {
  let res = {}
  Object.keys(obj)
    .forEach(i => {
      res[i] = obj[i].map(k => ({ title: k, checked: true }))
    })
  return res
}


/**
 * 防抖
 * @param {Function} fn 
 * @param {number} delay ms
 * @returns 
 */
export function debounce(fn, delay = 300) {
  let timer = null
  return function (...args) {
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      clearTimeout(timer)
      timer = null
      // fn.call(this, ...args)
      fn.apply(null, args)
    }, delay)
  }
}

/**
 * 节流
 * @param {Function} fn 
 * @param {number} delay ms
 * @returns 
 */
export function throttle(fn, delay = 300) {
  let lastTime = 0
  return function (...args) {
    if (Date.now() - lastTime < delay) return
    lastTime = Date.now()
    // fn.call(this, ...args)
    fn.apply(null, args)
  }
}

/**
 * 随机字符串, 只有字母,数字,下划线
 * @param {number} len 长度
 */
export function generateNonce(len = 24) {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_'
  let noceStr = '',
    maxPos = chars.length
  while (len--) noceStr += chars[(Math.random() * maxPos) | 0]
  return noceStr
}


/**
 * 生成 n 个 str 组成的字符串
 * @param {number} n 
 * @param {string} str 默认 '0' 
 * @returns 
 */
export function nStr(n = 0, str = '0') {
  let s = ''
  str += ''
  while (n-- > 0) {
    s = str + s
  }
  return s
}
// 指定长度，不够前面加 0
/**
 * 格式化字符串长度，不足以'0'补齐 (例: '00xx')
 * @param {string} str 
 * @param {number} n 字符串总长
 * @returns 
 */
export function strN0(str = '', n = 2) {
  str = str + ''
  n = n - str.length
  if (n > 0) str = nStr(n) + str
  return str
}

