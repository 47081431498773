<!--
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-05-18 10:13:45
 * @FilePath: \js-yigrow-system\src\components\toolbar\main-appbar.vue
 * @Description: 顶部操作栏
-->
<template>
  <!--App Bar -->
  <v-app-bar :density="mdAndUp ? 'default' : 'compact'">
    <!-- search input mobil -->
    <div class="d-flex flex-fill align-center" v-if="showMobileSearch">
      <v-text-field color="primary" variant="solo" prepend-inner-icon="mdi-magnify" append-inner-icon="mdi-close"
        @click:append-inner="showMobileSearch = false" hide-details placeholder="搜索"></v-text-field>
    </div>
    <div v-else class="px-2 d-flex align-center justify-space-between w-100">
      <!-- NavIcon -->
      <v-app-bar-nav-icon @click="customizeTheme.mainSidebar = !customizeTheme.mainSidebar"></v-app-bar-nav-icon>

      <!-- PC端搜索框 -->
      <!-- <div>
        <v-text-field
          v-if="mdAndUp"
          class="ml-2"
          style="width: 400px"
          color="primary"
          variant="solo"
          density="compact"
          prepend-inner-icon="mdi-magnify"
          hide-details
          placeholder="搜索"
        ></v-text-field>
      </div> -->

      <v-spacer></v-spacer>

      <div class="d-flex">
        <!-- 移动端搜索按钮 -->
        <!-- <v-btn v-if="!mdAndUp" icon @click="showMobileSearch = true">
          <v-icon>mdi-magnify</v-icon>
        </v-btn> -->
        <!-- search input desktop -->

        <!-- 新增设备 -->
        <v-btn icon @click="bindDevice">
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>

        <!-- 回首页 -->
        <v-btn icon v-if="isShowHomeBtn" @click="router.push('/')">
          <v-icon>mdi-home-import-outline</v-icon>
        </v-btn>

        <!-- 团队信息 -->
        <v-btn v-if="mdAndUp" icon>
          <v-badge dot color="success">
            <v-icon>mdi-account-multiple-outline</v-icon>
          </v-badge>
        </v-btn>

        <!-- 通知 -->
        <toolbar-notifications />

        <!-- 切换语言 -->
        <language-switcher />

        <!-- 账户操作 -->
        <toolbar-user />
      </div>
    </div>
  </v-app-bar>
</template>

<script setup>
import languageSwitcher from "./language-switcher.vue"
import toolbarNotifications from "./toolbar-notifications.vue"
import toolbarUser from "./toolbar-user.vue"
import { useDisplay } from "vuetify"
import { useCustomizeThemeStore } from "@/stores/customize-theme"

const { mdAndUp } = useDisplay()
const customizeTheme = useCustomizeThemeStore()
const router = useRouter()
const route = useRoute()

const showMobileSearch = ref(false)

const isShowHomeBtn = computed(() => {
  return route.path === '/home-page' ? false : true
},)

const bindDevice = () => {
  router.push({
    name: "scan-bind",
  })
}

</script>

<style scoped lang="scss"></style>
