/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-05-24 11:46:11
 * @FilePath: \js-yigrow-system\src\stores\app-store.js
 * @Description: app项目共同使用部分
 */

import { defineStore } from 'pinia'

export const useAppStore = defineStore({
  id: "appStore",
  state: () => ({
    globalLoading: false,
    isJumpProduct: false, // 是否需要直接跳转产品页面
  }),

  persist: {
    paths: ['globalLoading', 'isJumpProduct'],
    storage: sessionStorage,
  },

  getters: {},
  actions: {
    setGlobalLoading(val) {
      console.log(val)
      this.globalLoading = val
    },
    setIsJumpProduct(val) {
      this.isJumpProduct = val
    },
  },
})