/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-11-27 14:34:20
 * @FilePath: \h5-front\src\router\water-ferti.js
 * @Description: 产品管理
 */



const waterFertiCenter = () => import(/* webpackChunkName: "water-ferti" */ "@/views/water-ferti/water-ferti-center/water-ferti-page.vue")
const fogSystem = () => import(/* webpackChunkName: "fog-system" */ "@/views/water-ferti/fog-system/fog-page.vue")
const dosingSystem = () => import(/* webpackChunkName: "dosing-system" */ "@/views/water-ferti/dosing-system/index.vue")
const vegetablePlant = () => import(/* webpackChunkName: "vegetable-plant" */ "@/views/water-ferti/vegetable-plant/vege-index.vue")
const heatControl = () => import(/* webpackChunkName: "heat-control" */ "@/views/water-ferti/heat-control/heat-index.vue")
const inOutWaterFerti = () => import(/* webpackChunkName: "inOut-waterFerti" */ "@/views/water-ferti/inOut-waterFerti/water-ferti.vue")


export default [
    // 水肥中心
    {
        path: "/water-ferti/water-ferti-center",
        name: "water-ferti",
        component: waterFertiCenter,
        meta: {
            layout: "ui",
            pertain: 'menuName.productManage',
            title: "menuName.waterFertilizerCenter",
        },
    },
    // 高压喷雾系统
    {
        path: "/water-ferti/fog-system",
        name: "fog-system",
        component: fogSystem,
        meta: {
            layout: "ui",
            pertain: 'menuName.productManage',
            title: "menuName.spraySystem",
        },
    },
    // 打药策略
    {
        path: "/children/strategy",
        name: "dosing-strategy",
        component: () => import("@/views/water-ferti/dosing-system/children/strategy.vue"),
        meta: {
            layout: "default",
            pertain: 'menuName.drugSystem',
            title: "drugSystem.drugStrategy",
        },
    },
    // 打药工艺
    {
        path: "/children/process",
        name: "dosing-process",
        component: () => import("@/views/water-ferti/dosing-system/children/process.vue"),
        meta: {
            layout: "default",
            pertain: 'menuName.drugSystem',
            title: "drugSystem.drugTechnology",
        },
    },
    // 单次打药设定
    {
        path: "/children/singleMedicine",
        name: "dosing-single",
        component: () => import("@/views/water-ferti/dosing-system/children/singleMedicine.vue"),
        meta: {
            layout: "default",
            pertain: 'menuName.drugSystem',
            title: "drugSystem.singleDrugSet",
        },
    },
    // 设备管理
    {
        path: "/children/medicineDevice",
        name: "dosing-device",
        component: () => import("@/views/water-ferti/dosing-system/children/medicineDevice.vue"),
        meta: {
            layout: "default",
            pertain: 'menuName.drugSystem',
            title: "menuName.deviceManage",
        },
    },
    /** */


    /** */
    // 打药系统
    {
        path: "/water-ferti/dosing-system",
        name: "dosing-system",
        component: dosingSystem,
        meta: {
            layout: "ui",
            pertain: 'menuName.productManage',
            title: "menuName.drugSystem",
        },
    },
    // 阳台种菜机
    {
        path: "/water-ferti/vegetable-plant",
        name: "vegetable-plant",
        component: vegetablePlant,
        meta: {
            layout: "ui",
            pertain: 'menuName.productManage',
            title: "menuName.balconyGarden",
        },
    },
    // 加热控制
    {
        path: "/water-ferti/heat-control",
        name: "heat-control",
        component: heatControl,
        meta: {
            layout: "ui",
            pertain: 'menuName.productManage',
            title: "menuName.heatingControl",
        },
    },
    // 进取型水肥系统
    {
        path: "/water-ferti/inOut-waterFerti",
        name: "inOut-waterFerti",
        component: inOutWaterFerti,
        meta: {
            layout: "ui",
            pertain: 'menuName.productManage',
            title: "menuName.inOutWaterFertilizer",
        },
    },
    
]