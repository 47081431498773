/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-11-27 14:30:51
 * @FilePath: \h5-front\src\locales\en.js
 * @Description:
 */
import { vuetify_inner_en } from './vuetify_inner.js'

export default {
  // Vuetify components translations
  $vuetify: vuetify_inner_en,

  app: {
    appTitle: 'Yigrow OS',
    welcomeText:
      'Welcome to use Yigrow Agricultural automation management system ! Make planting easier !',
    systemTitle: 'Yigrow OS Management System'
  },
  common: {
    add: 'Add',
    cancel: 'Cancel',
    confirm: 'Confirm',
    save: 'Save',
    delete: 'Delete',
    edit: 'Edit',
    title: 'Title',
    description: 'Description',
    faq: 'FAQ',
    contact: 'Contact Us',
    tos: 'Terms of Service',
    policy: 'Privacy Policy',
    bind: 'Bind',
    search: 'search',
    more: 'more',
    noData: 'No data',
    operationSuccess: 'The operation command has been sent !',
    operationFail: 'Failed to send operation command !',
    open: 'Open',
    close: 'Close',
    idleMode: 'Idle Mode',
    selectStrategy: 'Select Strategy',
    enable: 'Enable',
    disable: 'Disable',
    query: 'Query',

    sensor: 'Sensor'
  },
  login: {
    welcome: 'Welcome',
    loginAccount: 'Login to your account',
    title: 'Sign In',
    email: 'Email',
    phone: 'Phone',
    password: 'Password',
    getCode: 'get code',
    validateCode: 'validate code',
    getCodeAgain: 'get code again',
    rememberPassword: 'Remember Password',
    button: 'Sign In',
    orsign: 'Or sign in with',
    forgot: 'Forgot password',
    noaccount: "Don't have an account?",
    haveaccount: 'Already have an account.',
    create: 'Create one here',
    error: 'The email / password combination is invalid',
    register: 'register',
    agree: 'Signing means that you agree.',
    manage_account: 'Manage your account',

    // 忘记密码
    findPassword:
      'Please enter the account you want to retrieve your password.',
    findAccountTips: 'Please enter your username or phone number.',
    newPassword: 'New password',
    newPasswordTips: 'Please enter a new password',
    confirmPassword: 'Confirm password',
    newPasswordAgain: 'Please enter a new password again.',
    confirmPasswordTips:
      'Confirm that the password is inconsistent with the new password',
    passwordResetSuccess: 'Password reset successfully'
  },
  menuName: {
    home: 'Home',
    deviceAdd: 'Device Add',
    productAdd: 'Product Edit',
    greenhouseManage: 'Greenhouse Manage',
    controlCenter: 'Control Center',
    preConfigured: 'Pre-configured',
    waterFertilizerManage: 'Water Fertilizer Manage',
    productManage: 'Product Manage',
    drugSystem: 'Drug System',
    waterFertilizerCenter: 'Water Fertilizer Center',
    spraySystem: 'Spray System',
    balconyGarden: 'Balcony Garden',
    heatingControl: 'Heating Control',
    inOutWaterFertilizer: 'In/Out Water Fertilizer',
    permissionManage: 'Permission Manage',
    userManage: 'User Manage',
    operationManage: 'operation management',
    baseManage: 'Base Manage',
    shedManage: 'Shed Manage',
    zoneManage: 'Zone Manage',
    positionManage: 'Position Manage',
    deviceManage: 'Device Manage'
  },
  home: {
    quickApp: 'Quick app',
    rightAdd: '* Right add quick app',
    myApp: 'My app',
    allApp: 'All app',
    dragSort: 'Long by drag sort',
    myAppTips: "* Please add this category to ' All apps'",
    allAppTips:
      "* This category label does not exist or is already in ' My Application '.",

    //
    baseWeather: 'Base Weather',
    scanBind: 'Scan Bind',
    serialNumber: 'Serial Number'
  },
  controlCenter: {
    deviceControl: 'Device Control'
  },
  drugSystem: {
    drugStrategy: 'Drug Strategy',
    drugTechnology: 'Drug Technology',
    singleDrugSet: 'Single Drug Set',
    noDrugSystem:
      ' * Current base does not have a spray system, please contact the administrator',
    noWaterFertiSystem:
      '* Current base does not have a waterferti system, please contact the administrator',
    noParameters: 'Direct-start equipment, no parameters',
    deviceNotEnable: 'Device not enabled',
    setPressure: 'Set pressure',
    singleDrugMode: 'Single drug mode',
    currentPressure: 'Current pressure',
    currentFrequency: 'Current frequency'
  },
  plantSystem: {
    singleWaterMode: 'Single watering mode'
  },
  fertilizerSystem: {
    singleWaterFertiMode: ' Single watering fertilization mode',
    autoWaterFertiMode: ' Auto watering fertilization mode'
  },
  alarmCenter: {
    alarmCenter: 'Alarm Center',
    alarmRecord:'Alarm Record',
    pushConfigure : 'Push Configure'
  }
}
