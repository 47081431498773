import { Base } from "./base"


class Wx extends Base {
  constructor(opt) {
    super(opt)
  }

  async getUserInfo() {
    return this.get('/user-info')
  }

  async loginByCode(params) {
    return this.get('/login', { params })
  }

  async registerByCode(params) {
    return this.get('/register', { params })
  }

  async bindingByCode({ code, phone, state, smsCode, password }) {
    return this.post('/binding', { code, phone, state, smsCode, password })
  }

  async bindingByCodeAndUsername({ code, username, password }) {
    return this.post('/binding-username', { code, username, password })
  }
}

export default new Wx({ prefix: '/v1/wx' })