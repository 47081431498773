<!--
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-06-25 17:43:21
 * @FilePath: \js-yigrow-system\src\components\common\time-picker.vue
 * @Description: 暂替vuetify3时间选择器
-->
<template>
  <v-dialog v-model="props.dialogVisible" persistent width="420">
    <v-card class="pt-4">
      <v-card-text>
        <div class="d-flex">
          <v-select
            v-model="selectedHour"
            :items="hours"
            variant="underlined"
            density="compact"
            style="width: 120px"
            hide-details
          ></v-select>
          <v-select
            v-model="selectedMinute"
            :items="minutes"
            variant="underlined"
            density="compact"
            style="width: 120px"
            hide-details
            class="mx-2"
          ></v-select>
          <v-select
            v-model="selectedSecond"
            :items="seconds"
            variant="underlined"
            density="compact"
            style="width: 120px"
            hide-details
          ></v-select>
        </div>
        <small class="text-info" v-if="false">
          {{
            `${
              selectedHour === '00' &&
              selectedMinute === '00' &&
              selectedSecond === '00'
                ? '* 请设置时长'
                : ''
            }`
          }}
        </small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text="取消" @click="closeDiaog"></v-btn>
        <v-btn text="确定" @click="updateTime"></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup>
const props = defineProps(['modelValue', 'dialogVisible'])

const emits = defineEmits([
  'confirmTime',
  'closeTimeDialog',
  'update:modelValue'
])

const hours = Array.from({ length: 24 }, (_, i) =>
  i.toString().padStart(2, '0')
)
const minutes = Array.from({ length: 60 }, (_, i) =>
  i.toString().padStart(2, '0')
)
const seconds = Array.from({ length: 60 }, (_, i) =>
  i.toString().padStart(2, '0')
)

const selectedHour = ref('00')
const selectedMinute = ref('00')
const selectedSecond = ref('00')

watchEffect(() => {
  if (props.modelValue) {
    const [hour, minute, second] = props.modelValue.split(':')
    selectedHour.value = hour.padStart(2, '0')
    selectedMinute.value = minute.padStart(2, '0')
    selectedSecond.value = second.padStart(2, '0')
  }
})

const closeDiaog = () => {
  emits('closeTimeDialog')
}

const updateTime = () => {
  const time = `${selectedHour.value}:${selectedMinute.value}:${selectedSecond.value}`
  emits('update:modelValue', time)
  emits('confirmTime')
  closeDiaog()
}
</script>
