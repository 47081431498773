/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-12-04 17:56:56
 * @FilePath: \h5-front\src\configs\menus\alarm-center.js
 * @Description: 报警中心菜单
 */

export default [
  {
    icon: 'mdi-list-box-outline',
    text: 'alarmCenter.alarmRecord', //报警记录
    link: '/alarm-center/alarm-record',
    name: 'alarm-record'
    //   menuKey: []
  },
  {
    icon: 'mdi-set-merge',
    text: 'alarmCenter.pushConfigure', //推送配置
    link: '/alarm-center/push-configure',
    name: 'push-configure',
    // menuKey: ['管理员']
  }
]
