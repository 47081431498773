<!--
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-11-28 11:48:12
 * @FilePath: \h5-front\src\components\toolbar\toolbar-user.vue
 * @Description: 个人账户中心
-->

<template>
  <v-menu :close-on-content-click="false" location="bottom right" transition="slide-y-transition">
    <template v-slot:activator="{ props }">
      <v-btn class="mx-2" icon v-bind="props">
        <v-badge content="2" color="success" dot bordered>
          <v-avatar size="40">
            <v-img v-if="userStore.userInfo?.avatarUrl" :src="userStore.userInfo?.avatarUrl"></v-img>
            <v-img v-else src="@/assets/avatar/IDR_PROFILE_AVATAR_39@1x.png"></v-img>
          </v-avatar>
        </v-badge>
      </v-btn>
    </template>
    <v-card max-width="300">
      <v-list lines="three" density="compact">
        <v-list-item>
          <template v-slot:prepend>
            <v-avatar size="40">
              <v-img v-if="userStore.userInfo?.avatarUrl" :src="userStore.userInfo?.avatarUrl"></v-img>
              <v-img v-else src="@/assets/avatar/IDR_PROFILE_AVATAR_39@1x.png"></v-img>
            </v-avatar>
          </template>

          <v-list-item-title class="font-weight-bold text-primary">
            {{ userStore.userInfo?.nickName || '匿名' }}
            <status-menu />
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ userStore.userInfo?.phone }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
      <v-divider />

      <v-list variant="flat" elevation="0" :lines="false" density="compact">
        <v-list-item v-for="(nav, i) in navs" :key="i" :to="nav.link" link density="compact">
          <template v-slot:prepend>
            <v-avatar size="30">
              <v-icon>{{ nav.icon }}</v-icon>
            </v-avatar>
          </template>

          <div>
            <v-list-item-subtitle class="text-body-2">
              {{ nav.title }}
            </v-list-item-subtitle>
          </div>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list variant="flat" elevation="0" :lines="false" density="compact">
        <v-list-item color="primary" to="nav.link" link density="compact">
          <template v-slot:prepend>
            <v-avatar size="30">
              <v-icon>mdi-lifebuoy</v-icon>
            </v-avatar>
          </template>

          <div>
            <v-list-item-subtitle class="text-body-2"> 帮助中心 </v-list-item-subtitle>
          </div>
        </v-list-item>
        <v-list-item color="primary" link @click="handleLogout" density="compact">
          <template v-slot:prepend>
            <v-avatar size="30">
              <v-icon>mdi-logout</v-icon>
            </v-avatar>
          </template>

          <div>
            <v-list-item-subtitle class="text-body-2"> 退出登录 </v-list-item-subtitle>
          </div>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script setup>
import { useUserStore } from "@/stores/user"
import statusMenu from "./status-menu.vue"
import { useBisStore } from '@/stores/bis.js'


const router = useRouter()
const userStore = useUserStore()

const handleLogout = () => {
  userStore.logout()
  useBisStore().logout()
  router.push({ name: 'login', })
}

const navs = [
  {
    title: '账户中心',
    link: '/profile',
    icon: 'mdi-account-box-outline'
  }
]
</script>

<style scoped lang="scss"></style>
