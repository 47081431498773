/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-06-20 09:03:26
 * @FilePath: \js-yigrow-system\src\models\group-yi-api.js
 * @Description: /group-yi 接口：基地-> 棚/分区/区域 -> 分组
 */

import { Base } from "./base"

class GroupYiService extends Base {
    constructor(opt) {
        super(opt)
    }

   // 查询所有分组
  getGroupYiList(params) {
    let url = '/all'
    return this.get(url, { params })
  }

  // 新增分组
  addGroupYi(params) {
    let url = '/'
    return this.post(url, params)
  }

  // 修改分组
  updateGroupYi(params) {
    let url = '/'
    return this.put(url, params)
  }

  // 删除分组
  deleteGroupYi(params) {
    let url = '/'
    return this.delete(url, { params })
  }

}

export default new GroupYiService({ prefix: '/v1/group-yi' })
