<!--
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-05-11 14:09:11
 * @FilePath: \js-yigrow-system\src\components\page-title.vue
 * @Description: 
-->
<template>
  <h1 class="text-h5 mt-5">{{ translate_i18n(title) }}</h1>
</template>

<script setup>
import { translate_i18n } from '@/plugins/i18n'
const route = useRoute()
const title = ref("")

watchEffect(() => {
  if (route.meta && route.meta.title) {
    title.value = route.meta.title
  }
})
</script>
