/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-03-14 15:40:33
 * @FilePath: \js-yigrow-system\src\configs\menus\authority-control.js
 * @Description:// 权限管理 
 * @ 没有menuKey，表示不验证权限，菜单中直接显示
 */

export default [
    {
        icon: "mdi-account-cog-outline",
        text: "menuName.userManage",//员工管理
        link: "/authority/sub-account",
        name:"sub-account",
        menuKey: ["管理员"],
    },
]