/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-12-02 14:02:38
 * @FilePath: \h5-front\src\router\alarm-center-router.js
 * @Description: 报警中心
 */

const alarmRecord = () => import(/* webpackChunkName: "alarm-record" */ '@/views/alarm-center/alarm-record.vue')
const pushConfigure = () => import(/* webpackChunkName: "alarm-record" */ '@/views/alarm-center/push-configure.vue')


export default [
  {
    path: '/alarm-center/alarm-record',
    name: 'alarm-record',
    component: alarmRecord,
    meta: {
      layout: 'ui',
      pertain: 'alarmCenter.alarmCenter',
      title: 'alarmCenter.alarmRecord'
    }
  },
  {
    path: '/alarm-center/push-configure',
    name: 'push-configure',
    component: pushConfigure,
    meta: {
      layout: 'ui',
      pertain: 'alarmCenter.alarmCenter',
      title: 'alarmCenter.pushConfigure'
    }
  },
]
