/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-04-16 10:37:59
 * @FilePath: \js-yigrow-system\src\plugins\i18n.js
 * @Description: 
 */
import { createI18n } from "vue-i18n"
import locales from "@/configs/locales"
const messages = locales.messages
const i18n = createI18n({
  legacy: false, // Vuetify 不支持 vue-i18n 的 legacy 模式
  locale: locales.locale, // 设置默认语言
  fallbackLocale: locales.fallbackLocale, // 设置回退语言
  messages,//设置语言包

  //vue-i18n插件在开发环境是默认打开警告，此操作 避免控制台警告
  silentTranslationWarn: true,
  missingWarn: false,
  silentFallbackWarn: true,
  fallbackWarn: false
})

export function translate_i18n(key) {
  return i18n.global.t(key)
}

export default i18n

/**
 * @description: 根据type(产品类型)，返回对应工作模式名称
 * @param {*} val   当前模式0,1,2,3，根据val的值返回模式的中文名
 * @param {*} type  drug表示打药系统，planting表示阳台种菜机
 * @return {*}
 */
export const getModeTitle = (val, type) => {
  const modeTitles = {
    drug: {
      0: translate_i18n('common.idleMode'), //空闲模式
      1: translate_i18n('drugSystem.singleDrugMode') //单次打药模式
    },
    planting: {
      0: translate_i18n('common.idleMode'),
      1: translate_i18n('plantSystem.singleWaterMode') //单次浇水模式
    },
    fertilizer: {
      0: translate_i18n('fertilizerSystem.singleWaterFertiMode'), //单次浇水施肥模式
      1: translate_i18n('fertilizerSystem.autoWaterFertiMode'), //自动浇水施肥模式
      2: translate_i18n('common.idleMode')
    }
  }
  return (modeTitles[type] && modeTitles[type][val]) || null
}