/**
 * 认证信息
 */
import { defineStore } from 'pinia'
import axios from 'axios'
import { isWechat } from '@/utils/utils.js'

const baseURL = import.meta.env.VITE_API_BASE_URL || ''

export const useAuthStore = defineStore(
  'auth',
  () => {
    const accessToken = ref() // 当前帐户  token
    const token = ref() // 当前帐户  token
    const refreshToken = ref() // 
    const isLogin = ref(false) // 
    const isBindingWechat = ref(true) // 是否绑定公众号
    const code = ref('') // 
    const redirect = ref() // 
    const phone = ref('') // 
    const uid = ref('') // 

    // logout
    function logoutRmToken() {
      accessToken.value = ''
      token.value = ''
      refreshToken.value = ''
      phone.value = ''
      uid.value = ''
      isLogin.value = false
      isBindingWechat.value = isWechat() ? false : null
    }

    function saveAuth(data) {
      accessToken.value = data.accessToken
      token.value = data.accessToken
      data.refreshToken && (refreshToken.value = data.refreshToken)
      data.phone && (phone.value = data.phone)
      uid.value = data.uid || data.id
      isLogin.value = true
    }

    async function getTokenByRefreshToken(token = refreshToken.value) {
      try {
        let res = await axios.get(`${baseURL}/v1/token/refresh?refreshToken=${token}`)
        saveAuth(res.data)
        return res.data
      } catch (err) {
        console.error('refresh token err:', err)
        throw new Error('refresh token err')
      }
    }

    return {
      accessToken, refreshToken, isLogin, token, phone, uid,
      code, redirect, isBindingWechat,
      logoutRmToken, saveAuth, getTokenByRefreshToken,
    }
  },
  {
    persist: [
      {
        paths: ['accessToken', 'refreshToken', 'token', 'redirect', 'phone', 'uid', 'isBindingWechat'],
        storage: localStorage,
      },
      {
        paths: ['isLogin', 'code'],
        storage: sessionStorage,
      },
    ],
    // true,  // default localStorage
    // persist: { key: 'store:app', } // default $id
  }
)