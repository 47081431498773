<!--
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-03-09 15:56:42
 * @FilePath: \js-yigrow-system\src\layouts\landing-layout.vue
 * @Description: landing主题
-->
<template>
  <!---Main Sidebar -->
  <main-sidebar />

  <!---Top AppBar -->
  <main-appbar />

  <!---MainArea -->
  <v-main class="main-container">
    <!-- 移动设别通过滑动控制菜单显示 -->
    <!-- v-touch="{
      left: () => (customizeTheme.mainSidebar = false),
      right: () => (customizeTheme.mainSidebar = true),
    }" -->

    <!-- <global-loading /> -->
    <!-- 右下角浮动按钮 -->
    <!-- <toolBox /> -->
    <div class="flex-fill">
      <slot></slot>
    </div>
  </v-main>
</template>

<script setup>
import { useCustomizeThemeStore } from "@/stores/customize-theme"
const customizeTheme = useCustomizeThemeStore()
</script>

<style scoped>
.scrollnav {
  height: calc(100vh - 326px);
}

.main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
