/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-03-02 17:18:24
 * @FilePath: \js-yigrow-system\src\models\user-api.js
 * @Description: /user 人员信息接口
 */

import { Base } from "./base"

/**
 * extends 表示继承Base这个类
 * 此时Base被称为父类，User被称为字类
 * 使用继承后，字类会拥有父类所有的方法和属性
 */
class UserService extends Base {
  // constructor表示构造函数
  constructor(opt) {
    //super关键字表示父类，调用父类的构造函数
    //字类的构造函数中，一定要调用父类的构造函数，否则对象无法初始化导致继承出现问题
    super(opt)
  }

  // async getUserInfo(userId = '') {
  //   return this.get('/info?userId=' + userId)
  // }

  async getUserPage({ count, page, } = {}) {
    return this.get('/page', { params: { count, page } })
  }

  async loginByCode(code, state) {
    return this.get('/login', { query: { code, state } })
  }

  async registerByCode(code, state) {
    return this.get('/register', { query: { code, state } })
  }

  async loginByUsername(username, password, captcha, tag) {
    return this.post('/login', { username, password, captcha, tag })
  }

  async getCaptcha() {
    return this.post('/captcha',)
    // return this.get('/captcha',)
  }

  async getUserList(page, pageSize, key) {
    let query = key ? { page, pageSize, key } : { page, pageSize }
    return this.get('/page', { query })
  }

  async getCustomerList(page, pageSize, key) {
    let query = key ? { page, pageSize, key } : { page, pageSize }
    return this.get('/customer-page', { query })
  }

  async getPermissions(userId) {
    return this.get('/permissions', { query: { userId } })
  }

  // 注册
  async addAdmin(data) {
    console.log(data)
    return this.post('/', data)
  }

  async updateAdmin(data) {
    return this.put('/', data)
  }

  // 查询当前用户所有关联商户
  async getMerchantAll() {
    return this.get('/bis/all')
  }
  async getBisAll() {
    return this.get('/bis/all')
  }

  // 查询用户信息（自己）
  async getUserInfo() {
    return this.get('/')
  }

  // 修改用户信息【自己】
  async updateUserInfo(data) {
    return this.put('/', data)
  }

}

// 使用并导出类
export default new UserService({ prefix: '/v1/user' })