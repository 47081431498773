import { vuetify_inner_zhHans } from "./vuetify_inner.js"

export default {
  // Vuetify 组件内部翻译
  $vuetify: vuetify_inner_zhHans,

  app: {
    appTitle: "易种OS",
    welcomeText: " 欢迎使用易种农业自动化管理系统！ 让种植更容易！",
    systemTitle: "易种OS管理系统",
  },
  common: {
    add: "新增",
    cancel: "取消",
    confirm: "确定",
    save: "保存",
    delete: "删除",
    edit:"编辑",
    title: "标题",
    description: "描述",
    faq: "常见问题",
    contact: "联系我们",
    tos: "服务条款",
    policy: "隐私政策",
    bind: "绑定",
    search: "搜索",
    more: "更多",
    noData: "暂无数据",
    operationSuccess: "操作命令已发送！",
    operationFail: "操作命令发送失败！",
    open: "开启",
    close: "关闭",
    idleMode: "空闲模式",
    selectStrategy: "选择策略",
    enable: "启用",
    disable: "不启用",
    query: "查询",

    sensor: "传感器",
  },
  login: {
    welcome: "欢迎",
    loginAccount: "登录你的账号",
    title: "登录",
    email: "电子邮件",
    phone: "手机号",
    password: "密码",
    getCode: "获取验证码",
    validateCode: "验证码",
    getCodeAgain: "重新获取",
    rememberPassword: "记住密码",
    button: "登录",
    orsign: "或使用",
    forgot: "忘记密码",
    noaccount: "还没有帐号？",
    haveaccount: "已经有账号了",
    create: "在此处创建一个",
    error: "密码组合无效",
    register: "注册",
    agree: "签署即表示您同意",
    manage_account: "创建易种OS管理账号",

    // 忘记密码
    findPassword: "请输入要找回密码的账号",
    findAccountTips: "请输入用户名或手机号",
    newPassword: "新密码",
    newPasswordTips: "请输入新密码",
    confirmPassword: "确认密码",
    newPasswordAgain: "请再次输入新密码",
    confirmPasswordTips: "确认密码与新密码不一致",
    passwordResetSuccess: "密码重置成功",
  },
  menuName: {
    home: "我的主页",
    deviceAdd: "设备添加",
    productAdd: "产品编辑",
    greenhouseManage: "温室管理",
    controlCenter: "环控中心",
    preConfigured:'预配置',
    waterFertilizerManage: "水肥管理",
    productManage: "产品管理",
    drugSystem: "打药系统",
    waterFertilizerCenter: "水肥中心",
    spraySystem: "喷雾系统",
    balconyGarden: "阳台种菜机",
    heatingControl: '加热控制',
    // 进取型水肥控制系统
    inOutWaterFertilizer: "进取型水肥控制系统",
    permissionManage: "权限管理",
    userManage: "员工管理",
    operationManage: "运营管理",
    baseManage: "基地管理",
    shedManage: "大棚管理",
    zoneManage: "区域管理",
    positionManage: "位置管理",
    deviceManage: "设备管理",
  },
  home: {
    quickApp: "快捷应用",
    rightAdd: "* 右侧添加快捷应用",
    myApp: "我的应用",
    allApp: "全部应用",
    dragSort: "长按拖动排序",
    myAppTips: "* 请在“全部应用”中添加此分类",
    allAppTips: "* 此分类标签不存在或已经在'我的应用'中了",

    //
    baseWeather: "基地小气候",
    scanBind: "扫码绑定",
    serialNumber: "序列号",
  },
  controlCenter: {
    deviceControl: "设备总控",
  },
  drugSystem: {
    drugStrategy: "打药策略",
    drugTechnology: "打药工艺",
    singleDrugSet: "单次打药设定",
    noDrugSystem: "当前基地没有打药系统，请联系管理员",
    noWaterFertiSystem: "当前基地没有水肥系统，请联系管理员",
    noParameters: "直启设备，无参数",
    deviceNotEnable: "设备未启用",
    setPressure: "设定压力",
    singleDrugMode: "单次打药模式",
    currentPressure: "当前压力",
    currentFrequency: "当前频率",
  },
  plantSystem: {
    singleWaterMode: "单次浇水模式",
  },
  fertilizerSystem: {
    singleWaterFertiMode: "单次浇水施肥",
    autoWaterFertiMode: "自动浇水施肥",
  },
  alarmCenter: {
    alarmCenter: '报警中心',
    alarmRecord:'报警记录',
    pushConfigure : '推送配置'
  }
}
