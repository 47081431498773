
import { rp } from './http.js'

export default function handleError(Vue,) {
  Vue.config.errorHandler = (err, instance, info) => {
    let e = err.message ? { message: err.message } : {}
    err.stack && (e.stack = err.stack)
    console.log('report err:', e, instance, info)
    rp.post(`/v1/report/error`, {
      err: e,
      instance,
      info,
      // location: window.location.href,
      // message: err.message,
      // stack: err.stack,
      // browserInfo: getBrowserInfo(),
      // user info
      // dom info
      // url info
      // ...
    }).then(res => console.log('report err ID:', res.reportId))
  }
}
