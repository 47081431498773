<!--
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-05-11 14:00:46
 * @FilePath: \js-yigrow-system\src\components\breadcrumb.vue
 * @Description: 面包屑
-->
<template>
  <v-breadcrumbs v-if="breadcrumbs.length > 0" :items="breadcrumbs" class="ml-n3 text-body-2">
    <template v-slot:prepend>
      <v-icon size="small" icon="mdi-home-outline" color="blue"></v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script setup>
import { translate_i18n } from '@/plugins/i18n'

const route = useRoute()
const breadcrumbs = ref([])

watchEffect(() => {
  // if you go to the redirect page, do not update the breadcrumbs
  //   if (route.path.startsWith('/redirect/')) {
  //     return
  //   }
  if (route.meta && route.meta.title) {
    breadcrumbs.value = [
      {
        title: translate_i18n(route.meta.pertain),
        disabled: false,
      },
      { title: translate_i18n(route.meta.title), disabled: true },
    ]
  } else {
    breadcrumbs.value = []
  }
})
</script>
