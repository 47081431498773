/*
 * @Author: OnismYY 2848335733@qq.com
 * @Date: 2023-12-08 14:55:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-03-05 11:31:49
 * @FilePath: \js-yigrow-system\src\configs\index.js
 * @Description: 
 */

import navigation from "./navigation"
// import locales from "./locales"

export * from './public'

export default {
  // product display information
  product: {
    name: "YiGrow Vuetify3",
    version: "1.0.0",
  },
  navigation,
  // locales,
  SUPER_VALUE: 2,
}



export const notLoginRoute = ['login', 'signup', 'auth-forgot', 'verify-email', 'error'] // 无需登录即可访问的路由 name,