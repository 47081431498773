/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-04-07 09:13:35
 * @FilePath: \js-yigrow-system\src\models\product-yi-api.js
 * @Description: /product-yi 产品接口
 */

import { Base } from "./base"

class ProductYiService extends Base {
    constructor(opt) {
        super(opt)
    }

    // 查询所有产品，查产品需要传入当前基地id
    async getProductAll(params) {
        let res = await this.get(`/all`, { params })
        return res
    }

    // 产品绑定
    async bindProduct(data) {
        let res = await this.post(`/binding`, data)
        return res
    }

}

export default new ProductYiService({ prefix: '/v1/product-yi' })