/**
 * 业务http服务
 *  showErr: [false], true 时自动 show 错误信息, 开发模式下自动显示
 *  handleError|throwError: [false], true 错误时返回给用户自己处理
 *  opt: {params}, params 会 qs 成 query 参数
 *
 */

import { showNotify } from "@/utils/show"
import router from "@/router/index.js"
import { rp } from "@/utils/http"
import { isDev, urlPrefix } from "@/configs/public"

// const request = new Http({ baseUrl, bisId, tokenName, })


function show(err) {
  let code = err.data?.code || err.code || err.status || ""
  let message = err.data?.message || err.message || err.statusText || err
  if (code === 500) message = "后台服务异常，请与管理员联系"
  else if (code) message = `${code}:${message}`
  console.log("http msg:", message)
  showNotify(message, 'error')
}

/**
 * Base类封装业务相关的 HTTP 请求，Base是父类
 * prefix 请求的 URL 前缀
 */
export class Base {
  // accessToken
  prefix = urlPrefix

  // 构造函数接收一个配置对象，可以通过该对象来设置 prefix 的值。
  constructor({ prefix } = {}) {
    prefix !== undefined && (this.prefix = prefix)
  }

  // 调用 http 方法实际发起 HTTP 请求。
  get(url, opt) {
    return this.http({ url, ...opt, method: "GET" })
  }
  post(url, data, opt) {
    return this.http({ url, data, ...opt, method: "POST" })
  }
  put(url, data, opt) {
    return this.http({ url, data, ...opt, method: "PUT" })
  }
  delete(url, opt) {
    return this.http({ url, ...opt, method: "DELETE" })
  }

  async http({ url, showErr, throwError = true, ...opt }) {
    // console.log(options, /options/)
    this.prefix && (url = this.prefix + url)
    try {
      let res = await rp({ url, throwError, ...opt }) // 服务器的信息；
      if (res?.code && (showErr || isDev)) {
        show(res)
      }
      return res
    } catch (e) {
      console.log('http err:', e)
      // if (showErr || isDev || e.status !== 401) show(e)
      if (e.statusCode === 401 || e.message === 'refresh token err:') {
        // logoutRmToken()
        return router.push({ name: "login", replace: true })
        // return useRouter().router.push({ name: "login", replace: true })
      }
      if (throwError) throw e
      // if (e.message === '帐号不能使用, 请与管理员联系. ') {
      // } else {
      //   if (isWechat()) location.href = redirectUri
      //   location.href = '/#/login'
      // }
      return null
    }
  }

  async add(data, url = "") {
    return this.post(url, data)
  }

  async remove(id, url = "") {
    return this.delete(url, { params: { id } })
  }

  async update(data, url = "") {
    return this.post(url, data)
  }

  async getInfo(query, url = "") {
    return this.get(url, { data: query })
  }
}
