<!--
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-05-24 14:33:29
 * @FilePath: \js-yigrow-system\src\components\navigation\main-menu.vue
 * @Description: 左侧菜单列表
-->
<template>
  <v-list class="menu-list" nav dense color="primary">
    <template v-for="menuArea in props.menu" :key="menuArea.key">
      <div v-if="!customizeTheme.miniSidebar && shouldShowMenuArea(menuArea)" class="pa-1 mt-2 text-overline">
        {{ translate_i18n(menuArea.text) }}
      </div>
      <template v-if="menuArea.items">
        <template v-for="menuItem in menuArea.items" :key="menuItem.key">
          <!-- menu level 1 -->
          <v-list-item
            v-if="shouldShowMenuItem(menuItem)"
            :to="menuItem.link"
            :prepend-icon="menuItem.icon || 'mdi-circle-medium'"
            :active-class="`active-nav-${customizeTheme.primaryColor.colorName}`"
            :active="isMenuItemActive(menuItem)"
            density="compact"
          >
            <v-list-item-title v-text="translate_i18n(menuItem.text)"></v-list-item-title>
          </v-list-item>

          <!-- 确保有子菜单时才渲染 -->
          <v-list-group v-else-if="menuItem && menuItem.items && menuItem.items.length > 0" :value="menuItem.items">
            <template v-slot:activator="{ props }">
              <v-list-item
                v-bind="props"
                :prepend-icon="menuItem.icon || 'mdi-circle-medium'"
                :title="translate_i18n(menuItem.text)"
              >
              </v-list-item>
            </template>
            <!-- menu level 2 -->
            <v-list-item
              v-for="subMenuItem in menuItem.items"
              :key="subMenuItem.key"
              :prepend-icon="subMenuItem.icon || 'mdi-circle-medium'"
              :title="translate_i18n(subMenuItem.text)"
              :to="subMenuItem.link"
              density="compact"
            ></v-list-item>
          </v-list-group>
        </template>
      </template>
    </template>
  </v-list>
</template>

<script setup>
import { translate_i18n } from '@/plugins/i18n'
import { useCustomizeThemeStore } from '@/stores/customize-theme'
import { useBisStore } from '@/stores/bis'
import { useFastStore } from '@/views/pages/fast-app/fast-store'

const fastStore = useFastStore()
const bisStore = useBisStore()
const customizeTheme = useCustomizeThemeStore()

const props = defineProps({
  menu: {
    type: Array,
    default: () => [],
  },
})
const route = useRoute()

/**！！！切换到children子页面，菜单默认选中主路由
 * 例如进入设备总控的任意菜单，通风、风机等子路由，默认选中菜单中的'设备总控'菜单
 */
const isMenuItemActive = (item) => {
  // 只在存在子路由的情况下判断
  if (route.matched[0]?.children.length > 0) {
    // 遍历主菜单项的子路由，确保属性存在再进行判断
    return route.matched[0]?.children.some((subRoute) => subRoute.meta && subRoute.meta.pertain === item.text)
  }
  // 若不存在子路由，判断当前路径是否匹配主菜单项的路径，返回状态，控制是否选中菜单
  const isActive = route.matched.some((matchedRoute) => matchedRoute.path === item.link)
  return isActive
}

/**
 * 是否显示菜单，假设当前菜单的menuKey中存在当前基地的产品类别，就显示菜单
 * 若直接没有menuKey字段，或不存在任何类别，不显示菜单
 */
const shouldShowMenuItem = (item) => {
  // 通过产品列表控制的菜单
  const baseCategories = bisStore.productTypeList || []

  // 主菜单中还要显示通过角色权限控制的菜单
  const userRoles = bisStore.rolePermission || []

  // 如果菜单项的 menuKey 存在，并且与当前基地的产品类别有交集，返回 true，显示菜单项
  return (
    !item.menuKey ||
    item.menuKey.some((pertain) => baseCategories.includes(pertain)) ||
    item.menuKey.some((pertain) => userRoles.includes(pertain))
  )
}

/**是否显示菜单标题 */
const shouldShowMenuArea = (menuArea) => {
  if (menuArea.items) {
    return menuArea.items.some((menuItem) => shouldShowMenuItem(menuItem))
  }
}

/** 获取所有能显示的菜单项，作为快捷应用所有选项 */
const getAllVisibleMenuNames = () => {
  const visibleMenuNames = []

  for (const menuArea of props.menu) {
    if (menuArea.items) {
      for (const menuItem of menuArea.items) {
        if (shouldShowMenuItem(menuItem)) {
          visibleMenuNames.push(menuItem.text)
        }
      }
    }
  }
  return visibleMenuNames
}
const visibleMenuNames = ref([])
onMounted(() => {
  visibleMenuNames.value = getAllVisibleMenuNames()
  fastStore.updateVisibleMenuNames(visibleMenuNames.value)
})
</script>

<style scoped>
/* 避免英文文本被设置为大写 */
.text-overline {
  text-transform: none !important;
}

.v-list-group .v-list-item {
  padding-left: 8px !important;
}

.active-nav-grey {
  border-left: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to bottom, #3a456c, #a4abbb);
}

.active-nav-purple {
  border-left: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to bottom, #e82893, #954bcb);
}

.active-nav-info {
  border-left: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to bottom, #487afa, #3fc7f3);
}

.active-nav-success {
  border-left: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to bottom, #45b95b, #96dd4c);
}

.active-nav-warning {
  border-left: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to bottom, #f0635d, #edc252);
}

.active-nav-error {
  border-left: 5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to bottom, #ea373a, #f07285);
}
</style>
